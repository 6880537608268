import i18n from 'i18next'
import {initReactI18next} from "react-i18next";

i18n.use(initReactI18next).init({
    debug:false,
    lng:"cat",
    resources: {
        cat:{
            translation:{
                titolInici: "PÀGINA D'INICI",
                namePages: ['Puntuar caminabilitat', 'Puntuar ciclabilitat', 'Resultats caminabilitat', 'Resultats ciclabilitat'],
                infoRankingSmallScreen: [' carrers puntuats', ' posició al ranking', 'Canviar d\'usuari'],
                search: "Buscar",
                buttonScore: "Puntuar",
                buttonDeleteLast: "Esborrar últim tram",
                buttonDeleteAll: "Esborrar tots els trams",
                scoreInfo: [
                    { title: "Distribució de l’espai", text: "Manera en com es distribueix l’espai. Hi ha espai compartit per peatons i altres vehicles? Quin tipus de carrer és? Peatonal, compartida..." },
                    { title: "Voreres", text: "Estat de les voreres, protecció entre la vorera i la carretera principal, obstacles que dificulten espai..." },
                    { title: "Escena urbana", text: "Entorn en el que es troba. Hi ha activitat comercial? Presencia d’arbres, bancs, papereres o fonts?" },
                    { title: "Seguretat", text: "Com se sent de segur en aquest carrer? La il·luminació és suficient? En quin estat està el carrer? Es veu brut o ple de grafitis?" },
                    { title: "Ambiental", text: "Hi ha massa soroll? Hi ha suficient sol i àrees verdes al teu entorn? Com notes la qualitat de l'aire i el nivell de pol·lució?" }
                ],
                warningSection: "Has de seleccionar un tram com a mínim",
                username: "Nom d'usuari",
                inputUsername: "Introdueix el teu nom d'usuari",
                confirm: "CONFIRMAR",
                ratedStreets: " carrers puntuats",
                rankingPos: " posició al ranking",
                logout: "Canviar d'usuari",
                sectionEv: "Ja has evaluat aquest tram",
                results: "Resultats",
                item1: "Voreres",
                item2: "Escena urbana",
                item3: "Seguretat",
                item4: "Ambiental",
                item5: "Distribució de l’espai",
                basedOn: "Basats en ",
                opinions: " opinions",
                error1: "Si us plau, activeu la vostra geolocalització",
                error2: "Posició no disponible",
                error3: "El vostre temps de sol·licitud s'ha esgotat",
                error4: "S'ha produït un error desconegut",
                error5: "Aquest navegador no admet la geolocalització, utilitzin un altre",
            }
        },
        es:{
            translation:{
                titolInici: "PÁGINA DE INICIO",
                namePages: ['Puntuar caminabilidad', 'Puntuar ciclabilidad', 'Resultados caminabilidad', 'Resultados ciclabilidad'],
                infoRankingSmallScreen: [' calles puntuadas', ' posición en el ranking', 'Cambiar de usuario'],
                search: "Buscar",
                buttonScore: "Puntuar",
                buttonDeleteLast: "Borrar último tramo",
                buttonDeleteAll: "Borrar todos los tramos",
                scoreInfo: [
                    { title: "Distribución del espacio", text: "Manera en cómo se distribuye el espacio. ¿Hay espacio compartido por peatones y otros vehículos? ¿Qué tipo de calle es? Peatonal, compartida..." },
                    { title: "Aceras", text: "Estado de las aceras, protección entre la acera y la carretera principal, obstáculos que dificultan espacio..." },
                    { title: "Escena urbana", text: "Entorno en el que se encuentra. ¿Hay actividad comercial? ¿Presencia de árboles, bancos, papeleras o fuentes?" },
                    { title: "Seguridad", text: "¿Cómo se siente de seguro en esta calle? ¿La iluminación es suficiente? ¿En qué estado está la calle? ¿Se ve sucio o lleno de grafitis?" },
                    { title: "Ambiental", text: "¿Hay demasiado ruido? ¿Hay suficiente sol y áreas verdes en tu entorno? ¿Cómo notas la calidad del aire y el nivel de polución?" }
                ],
                warningSection: "Debes seleccionar un tramo como mínimo",
                username: "Nombre de usuario",
                inputUsername: "Introduce tu nombre de usuario",
                confirm: "CONFIRMAR",
                ratedStreets: " calles puntuadas",
                rankingPos: " posición en el ranking",
                logout: "Cambiar de usuario",
                sectionEv: "Ya has evaluado este tramo",
                results: "Resultados",
                item1: "Aceras",
                item2: "Escena urbana",
                item3: "Seguridad",
                item4: "Ambiental",
                item5: "Distribución del espacio",
                basedOn: "Basado en ",
                opinions: " opiniones",
                error1: "Por favor, active su geolocalización",
                error2: "Posición no disponible",
                error3: "Su tiempo de solicitud se ha agotado",
                error4: "Se ha producido un error desconocido",
                error5: "Este navegador no admite la geolocalización, utilicen otro",
            }
        },
        en:{
            translation:{
                titolInici: "HOME PAGE",
                namePages: ['Score walkability', 'Score cyclability', 'Walkability results', 'Cyclability results'],
                infoRankingSmallScreen: [' rated streets', ' ranking position', 'Change user'],
                search: "Search",
                buttonScore: "Score",
                buttonDeleteLast: "Delete last section",
                buttonDeleteAll: "Delete all sections",
                scoreInfo: [
                    { title: "Distribution of space", text: "How the space is distributed. Is there a shared space by pedestrians and other vehicles? What type of street is it? Pedestrian, shared..." },
                    { title: "Sidewalks", text: "State of the sidewalks, protection between the sidewalk and the main road, obstacles that make space difficult..." },
                    { title: "Urban scene", text: "Environment in which the street is located. Is there commercial activity? Presence of trees, benches, trash cans or fountains?" },
                    { title: "Safety", text: "How safe does this street feel? Is the lighting sufficient? What is the condition of the street? Does it look dirty or full of graffiti?" },
                    { title: "Environmental", text: "Is there too much noise? Is there enough sun and green areas in the environment? How do you rate the air quality and pollution level?" }
                ],
                warningSection: "You must at least select one section",
                username: "Username",
                inputUsername: "Enter your username",
                confirm: "CONFIRM",
                ratedStreets: " rated streets",
                rankingPos: " ranking position",
                logout: "Change user",
                sectionEv: "You have already evaluated this section",
                results: "Results",
                item1: "Sidewalks",
                item2: "Urban scene",
                item3: "Safety",
                item4: "Environmental",
                item5: "Distribution of space",
                basedOn: "Based on ",
                opinions: " opinions",
                error1: "Please, enable your geolocation",
                error2: "Position unavailable",
                error3: "The request has timed out",
                error4: "An unknown error occurred",
                error5: "Geolocation is not supported by this browser, please change to another",
            }
        },
    },
});