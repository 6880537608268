import React, {createContext, useContext, useEffect, useState} from 'react';
import {APIContext} from "./RatingsAPI";

const SelectionContext = createContext();

export const useSelection = () => useContext(SelectionContext);

export const SelectionProvider = ({ children }) => {
    const [username, setUsername] = useState("");
    const [selected, setSelected] = useState([]);
    const [evaluated, setEvaluated] = useState([]);
    const [streetsRated, setStreetsRated] = useState("-");
    const [rankingPos, setRankingPos] = useState("-");
    const [lightGreenSections, setLightGreenSections] = useState([]);
    const [darkGreenSections, setDarkGreenSections] = useState([]);
    const [yellowSections, setYellowSections] = useState([]);
    const [orangeSections, setOrangeSections] = useState([]);
    const [redSections, setRedSections] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const {getUserInfo} = React.useContext(APIContext);

    useEffect(() => {
        if(username!==""){
            getUserInfo(username)
                .then((userInfo) => {
                    setStreetsRated(userInfo.total_street_count);
                    setRankingPos(userInfo.ranking);
                })
                .catch((error) => {
                    console.error("Error occurred:", error);
                });
        }
    }, [username, evaluated]);

    useEffect(() => {
        setRedSections([]);
        setOrangeSections([]);
        setYellowSections([]);
        setLightGreenSections([]);
        setDarkGreenSections([]);

        evaluated.forEach(item => {
            const ratingsSum = Number(item.rating_1) + Number(item.rating_2) + Number(item.rating_3) + Number(item.rating_4) + Number(item.rating_5);
            const averageRating = ratingsSum / 5;
            if (averageRating <= 1){
                setRedSections(prevState => [
                    ...prevState,
                    item.osm_id
                ]);
            } else if (averageRating <= 2){
                setOrangeSections(prevState => [
                    ...prevState,
                    item.osm_id
                ]);
            } else if (averageRating <= 3){
                setYellowSections(prevState => [
                    ...prevState,
                    item.osm_id
                ]);
            } else if (averageRating <= 4){
                setLightGreenSections(prevState => [
                    ...prevState,
                    item.osm_id
                ]);
            } else if (averageRating <= 5){
                setDarkGreenSections(prevState => [
                    ...prevState,
                    item.osm_id
                ]);
            }
        })
    }, [evaluated]);

    return (
        <SelectionContext.Provider value={{
            username, setUsername,
            selected, setSelected,
            evaluated, setEvaluated,
            streetsRated, setStreetsRated,
            rankingPos, setRankingPos,
            lightGreenSections, setLightGreenSections,
            darkGreenSections, setDarkGreenSections,
            yellowSections, setYellowSections,
            orangeSections, setOrangeSections,
            redSections, setRedSections,
            showResults, setShowResults,
            showWarning, setShowWarning
        }}>
            {children}
        </SelectionContext.Provider>
    );
};
