import React, {useContext, useEffect} from 'react';
import './InputBox.css';
import ClipLoader from "react-spinners/ClipLoader"
import {StreetsContext} from "../../Utils/StreetsAPI";
import {useTranslation} from "react-i18next";
import {Divider, InputBase} from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import NearMeIcon from '@mui/icons-material/NearMe';

function SearchIcon() {
    return null;
}

export default function InputBox({ onButtonClick, searchText, setSearchText, showSpinner }) {
    const { findStreetsByName, getUserLocationAndHandle } = useContext(StreetsContext);
    const {t} = useTranslation();


    useEffect(() => {
        findStreetsByName(searchText);
    }, [searchText]);

    return (
        <>
            <Paper component="form" id="paper">
                <InputBase
                    className="input"
                    placeholder={t("search")}
                    value={searchText}
                    style={{ paddingLeft: '10px' }}
                    onChange={(event) => {
                        setSearchText(event.target.value);
                        onButtonClick();
                    }}
                    onKeyDown={(event) =>{
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            findStreetsByName(searchText);
                            onButtonClick();
                        }
                    }}
                />

                <IconButton onClick={ () => {
                    findStreetsByName(searchText);
                    onButtonClick();
                }}>
                    <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                {
                    showSpinner ?
                        <IconButton sx={{ p: '10px' }} aria-label="nearme" onClick={getUserLocationAndHandle}>
                            <ClipLoader color={"#4c4c4c"} loading={showSpinner} size={30}/>
                        </IconButton>
                    :
                        <IconButton sx={{ p: '10px' }} aria-label="nearme" onClick={getUserLocationAndHandle}>
                            <NearMeIcon />
                        </IconButton>
                }
            </Paper>

        </>
    );
}
