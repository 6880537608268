import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelection} from "../../Utils/SelectionProvider";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

export default function DialogUsername({ setStreetsRated, setRankingPos, setOpenDialog}) {
    const [textField, setTextField] = useState("");
    const {t} = useTranslation();
    const { username, setUsername } = useSelection();

    const handleChange = (event) => {
        setTextField(event.target.value);
    };

    const handleClose = () => {
        if(textField !== "") {
            setUsername(textField);
            setOpenDialog(false);
        }
    };

    return (
        <>
            <Dialog
                style={{ zIndex: 9999 }}
                open={true}
            >
                <DialogTitle>{t("inputUsername")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="email"
                        label={t("username")}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                        onKeyDown={(event) =>{
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                handleClose();
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("confirm")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}