import React, {useContext, useEffect, useRef, useState} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import tramsBcnData from '../Data/Trams_BCN.json';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { useSelection } from '../Utils/SelectionProvider';
import ItemsMeasure from "./ItemsMeasure";
import InputBox from "./UI/InputBox";
import ListPlaces from "./UI/ListPlaces";
import StreetsAPI from "../Utils/StreetsAPI";
import {ItemContext} from "../Utils/ItemMng";
import Box from "@mui/material/Box";
import {Alert} from "@mui/material";

export default function Map( {handleLineClick, isItRank} ) {
    const mapRef = useRef(null);
    const { selected, evaluated, lightGreenSections, darkGreenSections, yellowSections, orangeSections, redSections, showResults, setShowResults } = useSelection();
    const { getLatitude, getLongitude } = useContext(ItemContext);
    const [mapInitialized, setMapInitialized] = useState(false);
    const [marker, setMarker] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [showErrorGeolocation, setShowErrorGeolocation] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const icon_map = L.icon({
        iconUrl: markerIconPng,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
    });

    // Intial
    useEffect(() => {
        const map = L.map('map', {
            scrollWheelZoom: !showResults,
            doubleClickZoom: false
        }).setView([getLatitude(), getLongitude()], 13);
        mapRef.current = map;

        L.tileLayer('https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        /*L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);*/

        setMapInitialized(true);

        return () => {
            map.remove();
        };
    }, []);

    // Update when coordinates change
    useEffect(() => {
        if(mapInitialized){
            const map = mapRef.current;
            map.setView([getLatitude(), getLongitude()], map.getZoom());

            if(firstTime){
                setFirstTime(false);
            }else{
                if(marker){
                    map.removeLayer(marker);
                }

                let newMarker = L.marker([getLatitude(), getLongitude()], { icon: icon_map }).addTo(map);
                setMarker(newMarker);
            }
        }
    }, [getLatitude, getLongitude, mapInitialized])


    // Updates scroll
    useEffect(() => {
        console.log(showResults);
        if (mapInitialized) {
            const map = mapRef.current;
            if(showResults){
                map.scrollWheelZoom.disable();
                console.log("zoom disabled");
            }else{
                map.scrollWheelZoom.enable();
                console.log("zoom enabled");
            }
        }
    }, [showResults]);

    // Updates the style of the map when selected changes and the map is initialised
    useEffect(() => {
        if (mapInitialized) {
            const map = mapRef.current;
            const updateMapStyle = (zoom) => {
                //We update the weight of the different lines depending on the level of zoom we are in
                let weight = 0;
                if (zoom <= 13) {
                    weight = 1;
                } else if (zoom === 14) {
                    weight = 1.75;
                } else if (zoom === 15) {
                    weight = 3;
                } else if (zoom === 16) {
                    weight = 8;
                } else if (zoom === 17) {
                    weight = 12;
                } else {
                    weight = 20;
                }

                const newStyle = {
                    weight: weight,
                    opacity: 1,
                };

                const styleLayer = L.geoJSON(tramsBcnData.features, {
                    //Here we are choosing the color, depending if the section is selected, evaluated or nothing
                    style: function (feature) {

                        const randomIndex = Math.floor(Math.random() * 4);

                        /*if (randomIndex <1) { // 20%
                            return { color: "rgba(42, 214, 59, 0.8)", ...newStyle };  // Green
                        } else if (randomIndex <2) { // 10%
                            return { color: "rgba(255, 255, 0, 0.8)", ...newStyle }; // Yellow
                        } else if (randomIndex <3) { // 15%
                            return { color: "rgba(255, 169, 31, 0.8)", ...newStyle }; // Orange
                        } else if (randomIndex  <4) { // 30%
                            return { color: "rgba(213, 37, 37, 0.8)", ...newStyle };  // Red
                        } else { // Remaining 25%te
                            return { color: "rgba(0, 138, 55, 0.8)", ...newStyle }; // Blue
                        }*/

                        let idStreet = feature.properties.C_Tram
                        if (selected.includes(idStreet)) {
                            return {color: "rgba(142, 202, 230, 0.8)", ...newStyle};
                        } else if (darkGreenSections.includes(idStreet)){
                            return {color: "rgba(0, 138, 55, 0.8)", ...newStyle};
                        } else if (lightGreenSections.includes(idStreet)){
                            return {color: "rgba(42, 214, 59, 0.8)", ...newStyle};
                        } else if (yellowSections.includes(idStreet)){
                            return {color: "rgba(255, 255, 0, 0.8)", ...newStyle};
                        } else if (orangeSections.includes(idStreet)){
                            return {color: "rgba(255, 169, 31, 0.8)", ...newStyle};
                        } else if (redSections.includes(idStreet)){
                            return {color: "rgba(213, 37, 37, 0.8)", ...newStyle};
                        } else {
                            return {color: "rgba(151, 153, 150, 0.8)", ...newStyle};
                        }
                    },

                    //When we click on a line
                    onEachFeature: function (feature, layer) {
                        layer.on('click', function (e) {
                            handleLineClick(feature);
                            updateMapStyle(map.getZoom());
                        });
                    }
                });

                //We remove the old style layer
                mapRef.current.eachLayer(layer => {
                    if (layer instanceof L.GeoJSON) {
                        mapRef.current.removeLayer(layer);
                    }
                });
                //And here we add a new one with the new style
                styleLayer.addTo(mapRef.current);
            };

            // Used to paint the map before doing zoom
            updateMapStyle(map.getZoom());

            // Detects when we zoom to update the weight of the lines
            map.on('zoomend', function (e) {
                const zoom = e.target._zoom;
                updateMapStyle(zoom);
            });
        }
    }, [selected, evaluated, lightGreenSections, darkGreenSections, yellowSections, orangeSections, redSections, mapInitialized]);

    return (
        <StreetsAPI setShowSpinner={setShowSpinner} setShowErrorGeolocation={setShowErrorGeolocation} setErrorMessage={setErrorMessage}>
            <div id="map" style={{width: '100%', height: '100%'}} onClick={() => setShowResults(false)}>
                <div style={{position: 'absolute', top: '20px', left: '60px', zIndex: '900'}}>
                    <div style={{minWidth: '30vw', width: 'auto'}}>
                        <InputBox
                        onButtonClick={() => setShowResults(true)}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        showSpinner={showSpinner}
                        />
                    </div>
                    <Box height={5} />
                    {showResults && (
                        <div style={{borderRadius: '8px', overflow: 'hidden'}}>
                            <ListPlaces
                                onItemClick={() => setShowResults(false)}
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    )}
                </div>
                {isItRank && (
                    <ItemsMeasure></ItemsMeasure>
                )}
                {showErrorGeolocation && (
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 500
                        }}
                        onClick={() =>
                            setShowErrorGeolocation(false)
                        }
                    >
                        <Alert severity="warning" style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '1000'
                        }}>
                            {errorMessage}
                        </Alert>
                    </div>
                )}
            </div>
        </StreetsAPI>
    );
}
