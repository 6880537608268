import "./App.css";
import React from 'react';
import {BrowserRouter as Router, HashRouter, Route, Routes} from "react-router-dom";
import WalkabilityMap from "./Pages/WalkabilityMap";
import "./Utils/i18n.jsx"
import {SelectionProvider} from "./Utils/SelectionProvider";
import RatingsAPI from "./Utils/RatingsAPI";
import CyclabilityMap from "./Pages/CyclabilityMap";
import ResultsCyclability from "./Pages/ResultsCyclability";
import ResultsWalkability from "./Pages/ResultsWalkability";
import ItemMng from "./Utils/ItemMng";

function App() {

    return (
        <div className="App">
            <HashRouter>
                <ItemMng>
                    <RatingsAPI>
                        <SelectionProvider>
                            <Routes>
                                <Route path="/" element={<WalkabilityMap />} />
                                <Route path="/walkabilityMap" element={<WalkabilityMap />} />
                                <Route path="/cyclabilityMap" element={<CyclabilityMap />} />
                                <Route path="/resultsWalkability" element={<ResultsWalkability />} />
                                <Route path="/resultsCyclability" element={<ResultsCyclability />} />
                            </Routes>
                        </SelectionProvider>
                    </RatingsAPI>
                </ItemMng>
            </HashRouter>
        </div>

    );

}

export default App;