import React, {useEffect, useState} from "react";
import tramsBcnData from './../Data/Trams_BCN.json';
import {ItemContext} from "./ItemMng";
import {useTranslation} from "react-i18next";

export const StreetsContext = React.createContext();

const StreetsAPI = ({ children, setShowSpinner, setShowErrorGeolocation, setErrorMessage }) => {
    const streets = Object.entries(tramsBcnData.features);
    const [listResults, setListResults] = React.useState([]);
    const { changeCoordinates } = React.useContext(ItemContext);
    const {t} = useTranslation();

    const getListResults = () => {
        return listResults;
    }

    const findStreetsByName = (streetName) => {
        let possibleStreets = streets.filter(street => {
            const words = street[1].properties.NVia_E.toLowerCase().split(' ');
            return words.length > 1 && words[1].startsWith(streetName.toLowerCase());
        });

        possibleStreets = possibleStreets.concat(
            streets.filter(street =>
                street[1].properties.NVia_E.toLowerCase().startsWith(streetName.toLowerCase())
            )
        );

        possibleStreets = possibleStreets.concat(
            streets.filter(street =>
                street[1].properties.NVia_E.toLowerCase().includes(streetName.toLowerCase())
            )
        );

        let uniqueStreets = {};
        let filteredResults = [];

        for (let i = 0; i < possibleStreets.length; i++) {
            let streetName = possibleStreets[i][1].properties.NVia_E;
            if (!uniqueStreets[streetName]) {
                uniqueStreets[streetName] = true;
                filteredResults.push(possibleStreets[i]);
            }
        }
        setListResults(filteredResults);
    }

    const getUserCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            setShowSpinner(true);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const location = {
                            coordinates: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        };
                        setShowSpinner(false);
                        resolve(location);
                    },
                    (error) => {
                        setShowSpinner(false);
                        setShowErrorGeolocation(true);
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                setErrorMessage(t("error1"));
                                break;
                            case error.POSITION_UNAVAILABLE:
                                setErrorMessage(t("error2"));
                                break;
                            case error.TIMEOUT:
                                setErrorMessage(t("error3"));
                                break;
                            case error.UNKNOWN_ERROR:
                                setErrorMessage(t("error4"));
                                break;
                        }
                        reject(error);
                    }
                );
            } else {
                setShowSpinner(false);
                setShowErrorGeolocation(true);
                setErrorMessage(t("error5"));
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    };


    const getUserLocationAndHandle = () => {
        getUserCurrentLocation()
            .then(location => {
                changeCoordinates(location.coordinates.lat, location.coordinates.lng);
            })
            .catch(error => {
                console.error("Error getting user's location:", error);
            });
    };

    return (
        <StreetsContext.Provider value={{getListResults, findStreetsByName, getUserLocationAndHandle}}>
            {children}
        </StreetsContext.Provider>
    );
};

export default StreetsAPI;
