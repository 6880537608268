import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import Map from "../Components/Map"
import NavBar from "../Components/NavBar";
import DialogUsername from "../Components/UI/DialogUsername";
import {useSelection} from "../Utils/SelectionProvider";
import {APIContext} from "../Utils/RatingsAPI";
import {Alert, DialogContent, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: 1,
    minWidth: 0,
    overflow: 'hidden',
}));

export default function ResultsWalkability() {
    const { username, setEvaluated } = useSelection();
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogResults, setOpenDialogResults] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { getWalkabilityAverage, getWalkabilityAverageByStreet } = React.useContext(APIContext);
    const {t} = useTranslation();
    const [opinions, setOpinions] = useState(0);
    const [items, setItems] = useState([0,0,0,0,0]);

    const handleLineClick = (feature) =>{
        setOpenDialogResults(true);
        getWalkabilityAverageByStreet(feature.properties.C_Tram)
            .then((result) => {
                setOpinions(result[0].count_rates);
                setItems( [
                    result[0].rating_1 !== 0 && result[0].rating_1 !== null ? Number(result[0].rating_1) : "-",
                    result[0].rating_2 !== 0 && result[0].rating_2 !== null ? Number(result[0].rating_2) : "-",
                    result[0].rating_3 !== 0 && result[0].rating_3 !== null ? Number(result[0].rating_3) : "-",
                    result[0].rating_4 !== 0 && result[0].rating_4 !== null ? Number(result[0].rating_4) : "-",
                    result[0].rating_5 !== 0 && result[0].rating_5 !== null ? Number(result[0].rating_5) : "-"
                ]);
            })
            .catch((err) => {

            });
    }

    useEffect(() => {
        if (username === "") {
            setOpenDialog(true);
        }else{
            setOpenDialog(false);
        }
    }, [username]);

    useEffect(() => {
        getWalkabilityAverage()
            .then((result) => {
                setEvaluated(result);
            })
            .catch((err) => {

            });
    }, []);

    const handleWarningClose = ()=>{
        setShowWarning(false);
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', height: '100dvh', overflow: 'hidden'}} onClick={() => setOpenDialogResults(false)}>
            <NavBar style={{height: '20dvh'}} setOpenDialog={setOpenDialog}></NavBar>

            <Map handleLineClick={handleLineClick} isItRank={false} style={{height: '100%'}}></Map>

            {openDialog && (
                <DialogUsername
                    setOpenDialog={setOpenDialog}
                ></DialogUsername>
            )}

            { openDialogResults && (
                <Dialog
                    style={{ zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    open={true}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle style={{ textAlign: 'center' }}>{t("results")}</DialogTitle>
                    <h5 style={{ textAlign: 'center' }}>{t("basedOn")} {opinions} {t("opinions")}</h5>
                    <DialogContent>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2}>
                                <Item style={{ minWidth: '125px' }}>
                                    {t("item1")}<h4>{items[0]}</h4>
                                </Item>
                                <Item style={{ minWidth: '125px' }}>
                                    {t("item2")}<h4>{items[1]}</h4>
                                </Item>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Item style={{ minWidth: '125px' }}>
                                    {t("item3")}<h4>{items[2]}</h4>
                                </Item>
                                <Item style={{ minWidth: '125px' }}>
                                    {t("item4")}<h4>{items[3]}</h4>
                                </Item>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <Item style={{ minWidth: '125px' }}>
                                    {t("item5")}<h4>{items[4]}</h4>
                                </Item>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}



            {showWarning && (
                <>
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 1500
                        }}
                        onClick={handleWarningClose}
                    ></div>
                    <Alert severity="warning" style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '2000'
                    }}>
                        {t("sectionEv")}
                    </Alert>
                </>
            )}
        </div>
    );

}
