import React from "react";

export const ItemContext = React.createContext();

const ItemMng = ({ children }) => {
    const [lat, setLat] = React.useState(41.3874);
    const [lon, setLon] = React.useState(2.1686);

    const changeCoordinates = (newLat, newLon) => {
        setLat(newLat);
        setLon(newLon);
    };

    const getLatitude = () => {
        return lat;
    }

    const getLongitude = () => {
        return lon;
    }

    return (
        <ItemContext.Provider value={{changeCoordinates, getLatitude, getLongitude }}>
            {children}
        </ItemContext.Provider>
    );
};

export default ItemMng;
