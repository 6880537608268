import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import Map from "../Components/Map"
import NavBar from "../Components/NavBar";
import DialogUsername from "../Components/UI/DialogUsername";
import {useSelection} from "../Utils/SelectionProvider";
import {APIContext} from "../Utils/RatingsAPI";
import {Alert} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function WalkabilityMap() {
    const { username } = useSelection();
    const [openDialog, setOpenDialog] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const { selected, setSelected, evaluated, setEvaluated } = useSelection();
    const { getWalkability } = React.useContext(APIContext);
    const {t} = useTranslation();

    const handleLineClick = (feature) =>{
        let idStreet = feature.properties.C_Tram
        if (evaluated.some(item => item.osm_id === idStreet)) {
            setShowWarning(true);
            setTimeout(() => {
                setShowWarning(false);
            }, 3000);
        } else if (selected.includes(idStreet)) {
            setSelected(prevSelected => {
                return prevSelected.filter(item => item !== idStreet);
            });
        } else {
            setSelected(prevSelected => {
                return [...prevSelected, idStreet];
            });
        }
    }

    useEffect(() => {
        if (username === "") {
            setOpenDialog(true);
        }else{
            setOpenDialog(false);
        }
    }, [username]);

    useEffect(() => {
        if (username) {
            getWalkability(username)
                .then((result) => {
                    setEvaluated(result);
                })
                .catch((err) => {

                });
        }
    }, [username, selected]);

    const handleWarningClose = ()=>{
        setShowWarning(false);
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', height: '100dvh', overflow: 'hidden'}}>
            <NavBar style={{height: '20dvh'}} setOpenDialog={setOpenDialog}></NavBar>

            <Map handleLineClick={handleLineClick} isItRank={true} style={{height: '100%'}}></Map>

            {openDialog && (
                <DialogUsername
                    setOpenDialog={setOpenDialog}
                ></DialogUsername>
            )}
            {showWarning && (
                <>
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 1500
                        }}
                        onClick={handleWarningClose}
                    ></div>
                    <Alert severity="warning" style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '2000'
                    }}>
                        {t("sectionEv")}
                    </Alert>
                </>
            )}
        </div>
    );

}
