import React from "react";

export const APIContext = React.createContext();

const RatingsAPI = ({ children }) => {
    const BASE_URL_USER = "https://backend-tfg-6f8q.onrender.com/api/users/";
    const BASE_URL_STREETS = "https://backend-tfg-6f8q.onrender.com/api/streets/";

    const getWalkability = (username) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getWalkability/${username}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const getCyclability = (username) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getCyclability/${username}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const rankWalkability = (username, streetId, ratings) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "osm_id": streetId,
                "rating_1": ratings[0],
                "rating_2": ratings[1],
                "rating_3": ratings[2],
                "rating_4": ratings[3],
                "rating_5": ratings[4],
                "username": username
            })
        };

        return fetch(`${BASE_URL_STREETS}rankWalkability`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const rankCyclability = (username, streetId, ratings) => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "osm_id": streetId,
                "rating_1": ratings[0],
                "rating_2": ratings[1],
                "rating_3": ratings[2],
                "rating_4": ratings[3],
                "rating_5": ratings[4],
                "username": username
            })
        };

        return fetch(`${BASE_URL_STREETS}rankCyclability`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const getUserInfo = (username) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_USER}getUserInfo/${username}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result)[0];
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    }

    const getWalkabilityAverage = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getWalkabilityAverage`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const getCyclabilityAverage = () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getCyclabilityAverage`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const getWalkabilityAverageByStreet = (street) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getWalkabilityAverageByStreet/${street}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    const getCyclabilityAverageByStreet = (street) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        return fetch(`${BASE_URL_STREETS}getCyclabilityAverageByStreet/${street}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then((result) => {
                return JSON.parse(result);
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                throw err;
            });
    };

    return (
        <APIContext.Provider value={{ getWalkability, getCyclability, rankWalkability, rankCyclability, getWalkabilityAverage, getCyclabilityAverage, getWalkabilityAverageByStreet, getCyclabilityAverageByStreet,getUserInfo }}>
            {children}
        </APIContext.Provider>
    );
};

export default RatingsAPI;
