import React, {useState} from 'react';
import {Alert, Rating, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import StarsIcon from "@mui/icons-material/Stars";
import UndoIcon from "@mui/icons-material/Undo";
import DeleteIcon from "@mui/icons-material/Delete";
import {useSelection} from "../Utils/SelectionProvider";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";
import {APIContext} from "../Utils/RatingsAPI";
import {useLocation} from "react-router-dom";

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function SimpleDialog(props: SimpleDialogProps & { selected: string[] } & { username: string, setSelected: React.Dispatch<React.SetStateAction<string[]>> }) {
    const [ratings, setRatings] = useState([0, 0, 0, 0, 0]);
    const { onClose, selectedValue, open, selected, username, setSelected } = props;
    const {t} = useTranslation();
    const { rankWalkability, rankCyclability } = React.useContext(APIContext);
    const location = useLocation();

    const handleClose = () => {
        setRatings([0, 0, 0, 0, 0]);
        onClose(selectedValue);
    };

    const rankStreet = () => {
        selected.forEach(streetId => {
            if (location.pathname === "/walkabilityMap" || location.pathname === "/") {
                rankWalkability(username, streetId, ratings);
            } else if (location.pathname === "/cyclabilityMap") {
                rankCyclability(username, streetId, ratings);
            }
        });
        setSelected([]);
        handleClose();
    }

    const handleRatingChange = (index: number, newValue: number) => {
        setRatings(prevRatings => {
            const updatedRatings = [...prevRatings];
            updatedRatings[index] = newValue;
            return updatedRatings;
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <List sx={{pt: 0}}>
                {t("scoreInfo", {returnObjects: true}).map((item, index) => (
                    <ListItem disableGutters key={index}>
                        <ListItemText sx={{ml: 2, mr: 1}}>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Rating
                                    name={`rating-${index}`}
                                    value={ratings[index]}
                                    onChange={(event, newValue) => {
                                        handleRatingChange(index, newValue);
                                    }}
                                />
                            </div>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                <Button variant="contained" onClick={rankStreet}
                        style={{width: 'fit-content'}}>{t("buttonScore")}</Button>
            </div>
        </Dialog>
    );
}


export default function ItemsMeasure() {
    const [open, setOpen] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const {selected, setSelected, username } = useSelection();
    const {t} = useTranslation();


    const handleClickOpen = (selectedItems) => {
        if (selectedItems.length > 0) {
            setOpen(true);
        } else {
            setShowWarning(true);
            setTimeout(() => {
                setShowWarning(false);
            }, 3000);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteLastSelected = () => {
        if (selected.length > 0) {
            const prevSelected = [...selected];
            prevSelected.pop();
            setSelected(prevSelected);
        }
    }

    const deleteAllSelected = () => {
        setSelected([]);
    }

    const handleWarningClose = () => {
        setShowWarning(false);
    }

    return(
        <>
            {showWarning && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 500
                    }}
                    onClick={handleWarningClose}
                ></div>
            )}

            <Stack
                direction="column"
                spacing={1.5}
                className="buttons-pc"
            >
                <Button variant="contained" startIcon={<StarsIcon/>} onClick={() => handleClickOpen(selected, username)}>
                    {t("buttonScore")}
                </Button>
                <Button variant="contained" startIcon={<UndoIcon/>} onClick={deleteLastSelected}>
                    {t("buttonDeleteLast")}
                </Button>
                <Button variant="contained" startIcon={<DeleteIcon/>} onClick={deleteAllSelected}>
                    {t("buttonDeleteAll")}
                </Button>
            </Stack>

            <Stack
                direction="column"
                spacing={1.5}
                className="buttons-phone"
            >
                <Button variant="contained" style={{justifyContent: 'center'}} onClick={() => handleClickOpen(selected, username)}> <StarsIcon/>
                </Button>
                <Button variant="contained" style={{justifyContent: 'center'}} onClick={deleteLastSelected}> <UndoIcon/>
                </Button>
                <Button variant="contained" style={{justifyContent: 'center'}} onClick={deleteAllSelected}>
                    <DeleteIcon/>
                </Button>
            </Stack>

            <SimpleDialog open={open} onClose={handleClose} selected={selected} setSelected={setSelected} username={username}/>

            {showWarning && (
                <Alert severity="warning" style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '1000'
                }}>
                    {t("warningSection")}
                </Alert>
            )}
        </>
    );
}