import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import StarsIcon from "@mui/icons-material/Stars";
import {Stack} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import LanguageIcon from '@mui/icons-material/Language';
import logo from "./../Assets/logoAcciona.svg"
import catFlag from "../Assets/Catalan.png"
import esFlag from "../Assets/Spanish.png"
import enFlag from "../Assets/English.png"
import {useTranslation} from "react-i18next";
import {useSelection} from "../Utils/SelectionProvider";
import {useNavigate} from "react-router-dom";

const languages = [
    {code: "cat", lang: "Català"},
    {code: "es", lang: "Español"},
    {code: "en", lang: "English"},
];

function NavBar({setOpenDialog}) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElLng, setAnchorElLng] = React.useState(null);
    const [anchorElLogout, setAnchorElLogout] = React.useState(null);
    const {i18n} = useTranslation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const { username, streetsRated, rankingPos } = useSelection();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenLngMenu = (event) => {
        setAnchorElLng(event.currentTarget);
    };

    const handleOpenLogoutMenu = (event) => {
        setAnchorElLogout(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (index) => {
        if(index === 2) setOpenDialog(true);
        setAnchorElUser(null);
    };

    const handleCloseLngMenu = (lng) => {
        if(typeof lng === 'string') i18n.changeLanguage(lng);
        setAnchorElLng(null);
    };

    const handleCloseLogoutMenu = (index) => {
       if(index) setOpenDialog(true);
        setAnchorElLogout(null);
    };

    const handleRedirect = (index) => {
        switch(index){
            case 0:
                return navigate('/walkabilityMap', {replace:true});
            case 1:
                return navigate('/cyclabilityMap', {replace:true});
            case 2:
                return navigate('/resultsWalkability', {replace:true});
            case 3:
                return navigate('/resultsCyclability', {replace:true});
            default:
                return 0;
        }
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/*Burger de quan hi ha la pantalla petita*/}
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', lg: 'none' }}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                            }}
                        >
                            {t("namePages", { returnObjects: true }).map((page, index) => (
                                <MenuItem key={index} onClick={() => handleRedirect(index)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/*Títol amb pantalla petita*/}
                    <div style={{
                        display: {
                            xs: 'flex',
                            sm: 'none',
                        },
                        alignItems: 'center',
                        flexGrow: 1
                    }}>
                        <img src={logo} alt="Acciona y Conecta logo" style={{
                            height: '40px',
                            display: 'block',
                            margin: 'auto',
                        }}/>
                    </div>

                    {/*Navegació amb pantalla gran*/}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                        {t("namePages", { returnObjects: true }).map((page, index) => (
                            <>
                                <Button
                                    key={index}
                                    onClick={() => handleRedirect(index)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                                <Box width={20} />
                            </>
                        ))}
                    </Box>

                    {/*Icones dreta pantalla gran*/}
                    <Stack sx={{ display: { xs: 'none', sm: 'flex' } }}
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                    >

                        <Box sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {username}
                        </Box>
                        <IconButton onClick={handleOpenLogoutMenu} sx={{p: 0}}>
                            <AccountCircleIcon sx={{ color: "#FFFFFF" }}/>
                        </IconButton>
                        <Menu
                            sx={{mt: '50px'}}
                            id="menu-appbar"
                            anchorEl={anchorElLogout}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElLogout)}
                            onClose={() => handleCloseLogoutMenu(false)}
                        >
                            <MenuItem onClick={() => handleCloseLogoutMenu(true)}>
                                <ListItemIcon>
                                    <ChangeCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography textAlign="left">{t("logout")}</Typography>} />
                            </MenuItem>
                        </Menu>
                        <Box width={8} />
                        {streetsRated}
                        <Tooltip title={streetsRated + t("ratedStreets")}>
                            <StarsIcon/>
                        </Tooltip>
                        <Box width={8} />
                        {rankingPos}
                        <Tooltip title={rankingPos + t("rankingPos")}>
                            <EmojiEventsIcon />
                        </Tooltip>
                    </Stack>

                    {/*Icon persona pantalla petita*/}
                    <Box sx={{flexGrow: 0, display: {xs: 'flex', sm: 'none'}}}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <AccountCircleIcon sx={{ color: "#FFFFFF" }} />
                        </IconButton>
                        <Menu
                            sx={{mt: '50px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={handleCloseUserMenu}>
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Typography textAlign="left">{username}</Typography>} />
                            </MenuItem>
                            {t("infoRankingSmallScreen", { returnObjects: true }).map((info, index) => (
                                <MenuItem key={index} onClick={() => handleCloseUserMenu(index)}>
                                    <ListItemIcon>
                                        {index === 0 && <StarsIcon />}
                                        {index === 1 && <EmojiEventsIcon />}
                                        {index === 2 && <ChangeCircleIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography textAlign="left">{index === 0 ? `${streetsRated} ${info}` : index === 1 ? `${rankingPos} ${info}` : info}</Typography>} />
                                </MenuItem>
                            ))}

                        </Menu>
                    </Box>

                    <Box width={20} />

                    {/*Idioma*/}
                    <Box>
                        <IconButton onClick={handleOpenLngMenu} sx={{p: 0}}>
                            <LanguageIcon sx={{ color: "#FFFFFF" }} />
                        </IconButton>
                        <Menu
                            sx={{mt: '50px'}}
                            id="menu-appbar"
                            anchorEl={anchorElLng}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElLng)}
                            onClose={handleCloseLngMenu}
                        >
                            {languages.map((lng) => (
                                <MenuItem key={lng.code} onClick={() => handleCloseLngMenu(lng.code)}>
                                    <ListItemIcon>
                                        {lng.code === 'cat' && <img src={catFlag} alt="Bandera catalana" style={{width: '15px', height: '10px'}}/>}
                                        {lng.code === 'es' && <img src={esFlag} alt="Bandera española" style={{width: '15px', height: '10px'}}/>}
                                        {lng.code === 'en' && <img src={enFlag} alt="English flag" style={{width: '15px', height: '10px'}}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography textAlign="left">{lng.lang}</Typography>}/>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>


                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default NavBar;
