import React, {useEffect, useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import {ItemContext} from "../../Utils/ItemMng";
import {StreetsContext} from "../../Utils/StreetsAPI";
import proj4 from "proj4";
import InfiniteScroll from "react-infinite-scroller";
import loadingGif from "../../Assets/loading.gif"


export default function ListPlaces({ onItemClick, searchText, setSearchText }) {
    const { changeCoordinates } = React.useContext(ItemContext);
    const {getListResults } = React.useContext(StreetsContext);

    const handleListItemClick = (item) => {
        let utm = "+proj=utm +zone=31";
        let wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        let coordsConverted = proj4(utm,wgs84,[item[1].properties.COORD_X,  item[1].properties.COORD_Y]);
        changeCoordinates(coordsConverted[1], coordsConverted[0]);
        onItemClick();
        setSearchText(item[1].properties.NVia_E)
        console.log(item);
    };

    const itemsPerPage = 20;
    const [hasMore, setHasMore] = useState(true);
    const [records, setRecords] = useState(itemsPerPage);

    useEffect(() => {
        setHasMore(true);
        setRecords(itemsPerPage);
    }, [searchText]);

    const loadMore = () => {
        if (records >= getListResults().length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                setRecords(records + itemsPerPage);
            }, 2000);
        }
    };

    const showItems = (posts) => {
        let items = [];
        let maxSize = getListResults().length;

        for (let i = 0; i < records; i++) {
            if (i === maxSize){
                break;
            }
            items.push(
                <ListItemButton
                    key={i}
                    style={{ backgroundColor: 'white' }}
                    onClick={(event) => handleListItemClick(posts[i])}
                >
                    <ListItemIcon>
                        <FmdGoodRoundedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={posts[i][1].properties.NVia_E}/>
                </ListItemButton>
            );
        }
        return items;
    };

    return (
        <div style={{maxHeight: '200px', overflowY: 'scroll'}}>
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={
                    <div key="div_loader" style={{backgroundColor: "white"}}>
                        <img src={loadingGif} alt="Loading gif" style={{width: '45px', height: '30px'}}/>
                        <div key="div_loader2" style={{height: "10px"}}></div>
                    </div>
                }
                useWindow={false}
            >
                {showItems(getListResults())}
            </InfiniteScroll>
        </div>

    );
}
